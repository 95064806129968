$_size_y_big: 30px;
$_size_y_small: 15px;

$_position_min: -5%;

$_opacity_min: 0;
$_opacity_max: 0.8;

$_animation_random: 15;

.deco {
  @for $i from 1 through 16 {
    &:nth-of-type(#{$i}) {
      // Random transition duration
      transition: all 1s + random($_animation_random) / $_animation_random;
      // Sizes - Top and Bottom
      @if $i % 2 == 0 {
        width: $_size_y_big;
      } @else {
        width: $_size_y_small;
      }

      // Positioning - Top
      left: 11% * $i;
      top: $_position_min;

      //animation-iteration-count: 5;
      animation: d-#{$i} 20s .4/random($_animation_random) + random($_animation_random)/$_animation_random + s linear infinite;
    }

  }
}

@media(hover: hover) {
  .deco {
    display: none;
  }
}

// Animation - Top
@for $i from 1 through 16 {
  @keyframes d-#{$i}{
    0%, 7.5%, 15%, 22.5% {
      opacity: $_opacity_min;
      transform: rotate(0);
    }
    2.5%, 10%, 17.5%, 25% {
      opacity: $_opacity_max;
      transform: translateY(200%) rotate(random(50) - 10deg);
    }
    5%, 12.5%, 20%, 27.5% {
      opacity: $_opacity_min;
      transform: translateY(400%) rotate(0);
    }
  }
}