$_size_y_big: 60px;
$_size_y_small: 30px;
$_size_x_big: 40px;
$_size_x_small: 20px;

$_position_min: -5%;
$_position_max: 90%;

$_opacity_min: 0;
$_opacity_max: 0.5;

$_animation_random: 10;

.deco {
  display: none;
}

@media(hover: hover) {
  .deco {
    display: block;
    @for $i from 1 through 25 {
      &:nth-of-type(#{$i}) {
        // Random transition duration
        transition: all 1s + random($_animation_random) / $_animation_random;

        @if $i <= 16 {
          // Sizes - Top and Bottom
          @if $i % 2 == 0 {
            width: $_size_y_big;
          } @else {
            width: $_size_y_small;
          }
        } @else {
          // Sizes - Left and Right
          @if $i % 2 == 0 {
            width: $_size_x_big;
          } @else {
            width: $_size_x_small;
          }
        }
      }
    }

    @for $i from 1 through 25 {
      @if $i <= 8 {
        // Positioning - Bottom
        &:nth-child(#{$i}) {
          left: 11% * ($i - 1);
          top: $_position_max;
        }
      } @else if $i <= 16 {
        // Positioning - Top
        &:nth-child(#{$i}) {
          left: 11% * ($i - 9);
          top: $_position_min;
        }
      } @else if $i <= 21 {
        // Positioning - Right
        &:nth-child(#{$i}) {
          left: $_position_max;
          top: 11% * ($i - 17) + 20%;
        }
      } @else {
        // Positioning - Left
        &:nth-child(#{$i}) {
          left: $_position_min;
          top: 11% * ($i - 22) + 30%;
        }
      }
    }
  }


  .button:hover .deco {
    @for $i from 1 through 25 {
      &:nth-of-type(#{$i}) {
        animation: d-#{$i} .7s .4/random($_animation_random) + random($_animation_random)/$_animation_random + s linear infinite;
        animation-iteration-count: 3;
      }
    }
  }
}

// Animation - Bottom
@for $i from 1 through 8 {
  @keyframes d-#{$i}{
    0% {
      opacity: $_opacity_min;
      transform: rotate(180deg);
    }
    50% {
      opacity: $_opacity_max;
      transform: translateY(100%) rotate(random(20) + 180deg);
    }
    100% {
      opacity: $_opacity_min;
      transform: translateY(200%) rotate(180deg);
    }
  }
}

// Animation - Top
@for $i from 9 through 16 {
  @keyframes d-#{$i}{
    0% {
      opacity: $_opacity_min;
      transform: rotate(0);
    }
    50% {
      opacity: $_opacity_max;
      transform: translateY(-100%) rotate(random(50) - 10deg);
    }
    100% {
      opacity: $_opacity_min;
      transform: translateY(-200%) rotate(0);
    }
  }
}

// Animation - Right
@for $i from 17 through 21 {
  @keyframes d-#{$i}{
    0% {
      opacity: $_opacity_min;
      transform: rotate(90deg);
    }
    50% {
      opacity: $_opacity_max;
      transform: translateX(75%) rotate(random(30) + 80deg);
    }
    100% {
      opacity: $_opacity_min;
      transform: translateX(150%) rotate(90deg);
    }
  }
}

// Animation - Left
@for $i from 22 through 25 {
  @keyframes d-#{$i}{
    0% {
      opacity: $_opacity_min;
      transform: rotate(-90deg);
    }
    50% {
      opacity: $_opacity_max;
      transform: translateX(-75%) rotate(random(90) - 100deg);
    }
    100% {
      opacity: $_opacity_min;
      transform: translatex(-150%) rotate(-90deg);
    }
  }
}